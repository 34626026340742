.sub-table{
  background-color:rgba(210,174,219,0.18);;
}

.user-row .MuiIconButton-root {
	margin-right:10px;
}

.user-row .iconButtonHolder{
	width:30px;
	height: 30px;
}


@media screen and (max-width: 850px) {
   #introduction #intro-description{
      font-size: 13px;
    }

  
}