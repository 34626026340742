
#company *{
	box-sizing: border-box;
}

#company{
	min-height: 50vh;
}

.company-container{
	padding: 20px 0;
}

.company-image-side{
	width:40%;
}

 .company-image-side img{
	max-width: 500px;
}

 .company-content-side{
	width: 60%;
}

.company-content .company-image-side img{
	padding: 0 30px;
	width:100%;
}

#company .app-description{
	letter-spacing: 2px;
}



.whatwedo .app-description{
	letter-spacing: 2px;
}


.student-session-form{
	position: relative;
}


.student-session-form .form-title{
	margin-top: 30px;
}

.student-session-form .MuiFormControl-root{
	margin-bottom: 30px !important;
}

@media screen and (max-width: 650px) {
     .company-image-side{
		width:100%;
	}

	 .company-content-side{
		width: 100%;
	}	
}