@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Fauna+One);

body {
  margin: 0;
  font-family: 'Fauna One', Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.row-flex{
	display:flex;
	width: 100%;
	flex-wrap: wrap;
	flex-direction: row;
}

.align-center{
	align-items: center;
}

.no-wrap{
	flex-wrap: nowrap !important;
}

.column-flex{
	display: flex;
	flex-direction: column;
}

.center-flex{
	justify-content: center;
	align-items: center;
}

.free-space{
	flex-basis:auto;
	flex-grow: 2;
}


.app-title{
	line-height: 100px;
	font-size: 60px;
	font-weight: 400;
  text-rendering: optimizeLegibility;
}

.app-subtitle{
    line-height: 1.333;
    text-rendering: optimizeLegibility;
    letter-spacing: 10px !important;
    color: rgba(0,0,0,0.8);
}

.app-subheader{
    line-height: 1.333;
    text-rendering: optimizeLegibility;
    letter-spacing: 1px !important;
    color: rgba(0,0,0,0.8);
    font-size: 12px;
}

.app-description{
	text-align: justify;
	line-height: 28px;
  color: rgba(0,0,0,0.8);
  font-size: 13px;
  font-weight: 550;
}


.background-cover{
	position: relative;
    background-size: cover;
}

.app-card{
	padding: 20px;
	border: solid 1px rgba(255,255,255,0.5);
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition: border-color, border-radius 1s;
    transition: border-color, border-radius 1s;
}


.app-card:hover{
	border-radius: 30px;
}

.app-button{
	border-radius: 12px !important;
}

.background-cover::before {    
      content: "";
      position: absolute;
      background-color: black;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.5;
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.flip-card-active{
	transform: rotateY(180deg);
}

/*.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}*/

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
}

.flip-card-back {
  transform: rotateY(180deg);
}

 .page-content{
  position: relative;
  width: 70%;
  margin: 0 auto;
}

 .page-padding{
  padding: 64px;
  box-sizing: border-box;
}

figure{
  margin: 0px !important;
}

.app-scroll::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.app-scroll::-webkit-scrollbar
{
  width: 12px;
  background-color: #F5F5F5;
}

.app-scroll::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}

.MuiTooltip-tooltip{
    font-family: 'Fauna One', Arial, sans-serif !important;
}

.app-footer{
  min-height: 30vh;
}


.swiper-wrapper {
  padding-bottom: 20px;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: -2px;
}

.swiper-button-prev, .swiper-button-next{
  color: #6a6a6a !important;
  fill: #6a6a6a;
}

.hide-swiper-button-next, .hide-swiper-button-prev{
    opacity: 0;
}



.react-tel-input .form-control{
  height: 56px;
  border-radius: 4px !important;
  width: 100% !important;
}

.react-tel-input:focus-within .special-label{
  color: #3f51b5;
}

.react-tel-input .form-control:focus {
    border-color: #3f51b5 !important;
    box-shadow: 0 0 0 1px #3f51b5 !important;
}

.error-MuiTextField-root .form-control{
    border-color: #f44336 !important;
}

.error-MuiTextField-root .special-label{
  color: #f44336 !important;
}


.error-MuiTextField-root .form-control:focus {
    border-color: #f44336 !important;
    box-shadow: 0 0 0 1px #f44336 !important;
}

.react-tel-input .special-label{
    color: rgba(0, 0, 0, 0.54);
    left: 5px !important;
}


.react-tel-input .form-control:focus ~ .special-label{
    color: #f44336;
}


.phoneinput-error{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin: 0 14px;
  color:#f44336;
  font-size: 0.75rem;
  line-height: 1.66;
}


.form-container .MuiTextField-root{
  height: 85px;
}

.form-container .MuiFormHelperText-root{
  line-height: 1;
}

.table-action, .table-action .MuiButton-root {
  color: rgba(0, 0, 0, 0.54) !important;
}

.table-action svg{
  color: rgba(0, 0, 0, 0.54) !important;
  fill: rgba(0, 0, 0, 0.54) !important;

}

.form-container .close-form{
  position: absolute;
  right: 10px;
  top: 10px;
}

.utility-bar-menu .MuiCheckbox-root{
  padding:0px;
  margin-right: 5px;
  margin-left: -10px;
}