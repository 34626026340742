.App{
  background-color: transparent !important;
}

.app-toolbar .sdk-breadcrumbs .MuiTypography-root{
  color: white;
}

.app-loading .MuiLinearProgress-barColorPrimary{
  background-color: #2e51ffb3;
}

.app-loading .MuiLinearProgress-root{
  background-color: rgba(150, 223, 153, 0.25);
}

.app-body{
  width:100%;
}

.app-body{
  margin-top:50px;
  min-height: 100vh;
}

.app-body>div{
  position: relative;
}

.app-toolbar {
  background-color:  rgb(0 0 0 / 50%) !important
}

.MuiTableCell-root .MuiCheckbox-root{
  padding: 0 !important;
}

.app-toolbar .MuiToolbar-root{
  padding: 0 16px !important;
}

.inline{
  display: block;
}

.fullscreen{
  display:none;
}

.menu-item{
  position: relative;
  cursor: pointer;
  text-decoration: none;
}


.menu-item .menu-item-title{
  color: white;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 3px;
  line-height: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}

.menu-item .menu-item-underline{
  position: absolute; 
  bottom: -5px; 
  left: 0; 
  width: 0; 
  height: 1.5px; 
  background-color: white; 
  transition: 0.2s;
}  

.menu-item:hover .menu-item-underline{
  width: 50%;
}

.active-page .menu-item-underline{
  width: 50%;
}

.logo{
  color: white !important;
  text-decoration: none;
  text-transform: initial !important;
  font-size: 24px;
  font-weight: 700;
  padding-right:20px;
}

.logo .logo-image{
    height: 40px;
}

.logo img{
  object-fit: fill;
  height: 100% !important;
}

.app-toolbar{
  transition: all 2s ease-in-out;
}

.transparent-toolbar{
  background-color: transparent !important;
  box-shadow: none !important;
}

.app-toolbar .bar-icon{
  display:none;
}


.app-footer .footer-container{
  flex: 20%;
}

.app-footer .app-subtitle{
  color: #bbc0c4;
}

.app-footer .footer-content a{
  text-decoration: none;
  color: #848d95;
  font-size: 13px;
}

.app-footer .footer-content a:hover{
  opacity: 0.5;
}

@media screen and (max-width: 950px) {
  .app-toolbar .bar-icon{
    display: block;
 }
  .inline{
    display: none;
  }
  .logo-title{
    display: none;
  }

  .fullscreen{
    display:block;
  }
  .App{
    width: 100vw !important;
  }
}

@media screen and (max-width: 650px) {
  .form-dialog .MuiDialog-paper{
    width:100vw !important;
    height:100vh !important;
    margin: 0px !important;
  }
}

@media screen and (max-width: 600px) {
  .app-body{
    margin-top: 40px;
  }
}

@media screen and (max-width: 600px) {
  .page-padding{
    padding: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .page-padding{
    padding: 20px;
  }
}