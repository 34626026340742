.login .login-container{
	width: 50%;
	max-width: 600px;
}

.login .MuiButtonBase-root{
	margin: 10px 0;
}

@media screen and (max-width: 1000px) {
   .login .login-container{
		width: 60%;
	}
}

@media screen and (max-width: 850px) {
   .login .login-container{
		width: 100%;
	}	
}