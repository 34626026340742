@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Fauna+One);
body {
  margin: 0;
  font-family: 'Fauna One', Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.row-flex{
	display:flex;
	width: 100%;
	flex-wrap: wrap;
	flex-direction: row;
}

.align-center{
	align-items: center;
}

.no-wrap{
	flex-wrap: nowrap !important;
}

.column-flex{
	display: flex;
	flex-direction: column;
}

.center-flex{
	justify-content: center;
	align-items: center;
}

.free-space{
	flex-basis:auto;
	flex-grow: 2;
}


.app-title{
	line-height: 100px;
	font-size: 60px;
	font-weight: 400;
  text-rendering: optimizeLegibility;
}

.app-subtitle{
    line-height: 1.333;
    text-rendering: optimizeLegibility;
    letter-spacing: 10px !important;
    color: rgba(0,0,0,0.8);
}

.app-subheader{
    line-height: 1.333;
    text-rendering: optimizeLegibility;
    letter-spacing: 1px !important;
    color: rgba(0,0,0,0.8);
    font-size: 12px;
}

.app-description{
	text-align: justify;
	line-height: 28px;
  color: rgba(0,0,0,0.8);
  font-size: 13px;
  font-weight: 550;
}


.background-cover{
	position: relative;
    background-size: cover;
}

.app-card{
	padding: 20px;
	border: solid 1px rgba(255,255,255,0.5);
	border-radius: 0px;
	background-color: transparent;
 transition: border-color, border-radius 1s;
}


.app-card:hover{
	border-radius: 30px;
}

.app-button{
	border-radius: 12px !important;
}

.background-cover::before {    
      content: "";
      position: absolute;
      background-color: black;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.5;
}

.flip-card {
  background-color: transparent;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.flip-card-active{
	-webkit-transform: rotateY(180deg);
	        transform: rotateY(180deg);
}

/*.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}*/

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
}

.flip-card-back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

 .page-content{
  position: relative;
  width: 70%;
  margin: 0 auto;
}

 .page-padding{
  padding: 64px;
  box-sizing: border-box;
}

figure{
  margin: 0px !important;
}

.app-scroll::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.app-scroll::-webkit-scrollbar
{
  width: 12px;
  background-color: #F5F5F5;
}

.app-scroll::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}

.MuiTooltip-tooltip{
    font-family: 'Fauna One', Arial, sans-serif !important;
}

.app-footer{
  min-height: 30vh;
}


.swiper-wrapper {
  padding-bottom: 20px;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: -2px;
}

.swiper-button-prev, .swiper-button-next{
  color: #6a6a6a !important;
  fill: #6a6a6a;
}

.hide-swiper-button-next, .hide-swiper-button-prev{
    opacity: 0;
}



.react-tel-input .form-control{
  height: 56px;
  border-radius: 4px !important;
  width: 100% !important;
}

.react-tel-input:focus-within .special-label{
  color: #3f51b5;
}

.react-tel-input .form-control:focus {
    border-color: #3f51b5 !important;
    box-shadow: 0 0 0 1px #3f51b5 !important;
}

.error-MuiTextField-root .form-control{
    border-color: #f44336 !important;
}

.error-MuiTextField-root .special-label{
  color: #f44336 !important;
}


.error-MuiTextField-root .form-control:focus {
    border-color: #f44336 !important;
    box-shadow: 0 0 0 1px #f44336 !important;
}

.react-tel-input .special-label{
    color: rgba(0, 0, 0, 0.54);
    left: 5px !important;
}


.react-tel-input .form-control:focus ~ .special-label{
    color: #f44336;
}


.phoneinput-error{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin: 0 14px;
  color:#f44336;
  font-size: 0.75rem;
  line-height: 1.66;
}


.form-container .MuiTextField-root{
  height: 85px;
}

.form-container .MuiFormHelperText-root{
  line-height: 1;
}

.table-action, .table-action .MuiButton-root {
  color: rgba(0, 0, 0, 0.54) !important;
}

.table-action svg{
  color: rgba(0, 0, 0, 0.54) !important;
  fill: rgba(0, 0, 0, 0.54) !important;

}

.form-container .close-form{
  position: absolute;
  right: 10px;
  top: 10px;
}

.utility-bar-menu .MuiCheckbox-root{
  padding:0px;
  margin-right: 5px;
  margin-left: -10px;
}
.inline .MuiSvgIcon-root{
    color: white !important;
}
.inline .MuiDrawer-paper{
    background-color:#282c34;
}

.menu-button{
	color: white !important;
}

.menu-button:hover{
	border-bottom: 1px white !important;
	border-bottom-width: 50%;
}

.inline-menu .menu-item{
	margin: 0 10px;
}

.inline-menu .menu-item .menu-item-underline{
    bottom: 10px;
}

.inline-menu .menu-item .menu-item-title{
    font-size: 12px !important;
}

@keyframes wave{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@-webkit-keyframes wave{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
.menu-icon{
	color: white;
}

.full-menu-container{
	position:relative;
}

.menu-screen{
	position: absolute;
	width: calc(100vw + 16px);
	max-height: 0vh;
	height:calc(100vh + 60px);
	top: -60px;
	left: -16px;
	z-index: 1001;
	overflow-y:auto;
	-webkit-transition: .2s ease-in-out;   
    -webkit-transition-delay: .2s; 
    -moz-transition: .2s ease-in-out;   
    -moz-transition-delay: .2s; 
    -ms-transition: .2s ease-in-out;   
    -ms-transition-delay: .2s; 
    -o-transition: .2s ease-in-out;   
    -o-transition-delay: .2s; 
    transition: .2s ease-in-out;   
    transition-delay: .2s; 
  	background-color: #282c34;

}


.full-menu .menu-open{
	max-height: calc(100vh + 48px);
}

.full-menu .menu-item{
	padding: 10px 0;
	margin: 10px 0;
}

.white-button-icon{
	color: white !important;

}

.new-account .form-container .MuiTextField-root{
  height: 90px;
}


.information .app-title{
	line-height: 100px;
	font-size: 60px;
	font-weight: 400;
	letter-spacing: 2px;
    text-rendering: optimizeLegibility;
}

.information .app-subtitle{
    line-height: 1.333;
    text-rendering: optimizeLegibility;
    font-weight: 600;
    letter-spacing: 5px !important;
   color: rgba(0,0,0,0.8);
}


.information .app-description{
	line-height: 28px;
    color: rgba(0,0,0,0.8);
    font-size: 13px;
    font-weight: 550;
}


.information .page-content{
	position: relative;
	width: 70%;
    margin: 0 auto;
}

.information .page-padding{
	padding: 64px;
	box-sizing: border-box;
}

@media screen and (max-width: 1400px) {
   .information .page-content{
		width: 80%;
	}

	.information .page-padding{
		padding: 40px;
	}	
}

@media screen and (max-width: 1000px) {
   .information .page-content{
		width: 90%;
	}

	.information .page-padding{
		padding: 40px;
	}	
}

@media screen and (max-width: 850px) {
   .information .page-content{
		width: 95%;
	}

	.information .page-padding{
		padding: 20px;
	}	

  	.information .app-title{
		font-size: 30px !important;
	}	
}
.login .login-container{
	width: 50%;
	max-width: 600px;
}

.login .MuiButtonBase-root{
	margin: 10px 0;
}

@media screen and (max-width: 1000px) {
   .login .login-container{
		width: 60%;
	}
}

@media screen and (max-width: 850px) {
   .login .login-container{
		width: 100%;
	}	
}

.utility-bar .search-fields:after, .search-fields:before{
	border-bottom: none !important;
}

.utility-bar .search-fields .MuiSelect-select:focus{
	background: none !important;
}

.utility-bar .search{
	border: 1.5px solid rgba(0,0,0,.3);
}

.utility-bar input{
	padding: 0 10px;
	border: none;
	outline: none !important;
}

.utility-bar{
	padding: 0px !important;
}

.utility-bar input{
	background: none;
}
.sub-table{
  background-color:rgba(210,174,219,0.18);;
}

.user-row .MuiIconButton-root {
	margin-right:10px;
}

.user-row .iconButtonHolder{
	width:30px;
	height: 30px;
}


@media screen and (max-width: 850px) {
   #introduction #intro-description{
      font-size: 13px;
    }

  
}

#company *{
	box-sizing: border-box;
}

#company{
	min-height: 50vh;
}

.company-container{
	padding: 20px 0;
}

.company-image-side{
	width:40%;
}

 .company-image-side img{
	max-width: 500px;
}

 .company-content-side{
	width: 60%;
}

.company-content .company-image-side img{
	padding: 0 30px;
	width:100%;
}

#company .app-description{
	letter-spacing: 2px;
}



.whatwedo .app-description{
	letter-spacing: 2px;
}


.student-session-form{
	position: relative;
}


.student-session-form .form-title{
	margin-top: 30px;
}

.student-session-form .MuiFormControl-root{
	margin-bottom: 30px !important;
}

@media screen and (max-width: 650px) {
     .company-image-side{
		width:100%;
	}

	 .company-content-side{
		width: 100%;
	}	
}
.table-action{
	margin: 0 10px !important;
}
.profile-picture{
	 background-color:#1976d2;
	 color:white;
	 border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.profile .personal-summary{
	width:50%;
}

.profile .personal-summary .field-container{
	min-height: 60px;
	align-items: center;
}

.profile .personal-summary .MuiDivider-root{
	height: 0.5px;
	border-style: none;
}

.personal-summary .field-container .field-label{
	width:30%;
}

.personal-summary .field-container-form .MuiInputBase-root, .personal-summary-input{
	width: 80%;
}

.profile .MuiCardContent-root:last-child{
	padding-bottom: 40px;
}


@media screen and (max-width: 1000px) {
  .profile .personal-summary{
    width:80%;
  }
}



@media screen and (max-width: 600px) {
  .profile .personal-summary{
    width:90%;
  }

  .personal-summary .field-container .field-label{
		width:100%;
	}

	.personal-summary .field-container-form .MuiInputBase-root, .personal-summary-input{
		width: 100%;
	}
}
.sdk-breadcrumbs{
	padding: 20px;
}

.login_alert{
	position: fixed;
	top:0px;
	width: 100%;
}
.App{
  background-color: transparent !important;
}

.app-toolbar .sdk-breadcrumbs .MuiTypography-root{
  color: white;
}

.app-loading .MuiLinearProgress-barColorPrimary{
  background-color: #2e51ffb3;
}

.app-loading .MuiLinearProgress-root{
  background-color: rgba(150, 223, 153, 0.25);
}

.app-body{
  width:100%;
}

.app-body{
  margin-top:50px;
  min-height: 100vh;
}

.app-body>div{
  position: relative;
}

.app-toolbar {
  background-color:  rgb(0 0 0 / 50%) !important
}

.MuiTableCell-root .MuiCheckbox-root{
  padding: 0 !important;
}

.app-toolbar .MuiToolbar-root{
  padding: 0 16px !important;
}

.inline{
  display: block;
}

.fullscreen{
  display:none;
}

.menu-item{
  position: relative;
  cursor: pointer;
  text-decoration: none;
}


.menu-item .menu-item-title{
  color: white;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 3px;
  line-height: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}

.menu-item .menu-item-underline{
  position: absolute; 
  bottom: -5px; 
  left: 0; 
  width: 0; 
  height: 1.5px; 
  background-color: white; 
  transition: 0.2s;
}  

.menu-item:hover .menu-item-underline{
  width: 50%;
}

.active-page .menu-item-underline{
  width: 50%;
}

.logo{
  color: white !important;
  text-decoration: none;
  text-transform: none !important;
  text-transform: initial !important;
  font-size: 24px;
  font-weight: 700;
  padding-right:20px;
}

.logo .logo-image{
    height: 40px;
}

.logo img{
  object-fit: fill;
  height: 100% !important;
}

.app-toolbar{
  transition: all 2s ease-in-out;
}

.transparent-toolbar{
  background-color: transparent !important;
  box-shadow: none !important;
}

.app-toolbar .bar-icon{
  display:none;
}


.app-footer .footer-container{
  flex: 20% 1;
}

.app-footer .app-subtitle{
  color: #bbc0c4;
}

.app-footer .footer-content a{
  text-decoration: none;
  color: #848d95;
  font-size: 13px;
}

.app-footer .footer-content a:hover{
  opacity: 0.5;
}

@media screen and (max-width: 950px) {
  .app-toolbar .bar-icon{
    display: block;
 }
  .inline{
    display: none;
  }
  .logo-title{
    display: none;
  }

  .fullscreen{
    display:block;
  }
  .App{
    width: 100vw !important;
  }
}

@media screen and (max-width: 650px) {
  .form-dialog .MuiDialog-paper{
    width:100vw !important;
    height:100vh !important;
    margin: 0px !important;
  }
}

@media screen and (max-width: 600px) {
  .app-body{
    margin-top: 40px;
  }
}

@media screen and (max-width: 600px) {
  .page-padding{
    padding: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .page-padding{
    padding: 20px;
  }
}
