.profile .personal-summary{
	width:50%;
}

.profile .personal-summary .field-container{
	min-height: 60px;
	align-items: center;
}

.profile .personal-summary .MuiDivider-root{
	height: 0.5px;
	border-style: none;
}

.personal-summary .field-container .field-label{
	width:30%;
}

.personal-summary .field-container-form .MuiInputBase-root, .personal-summary-input{
	width: 80%;
}

.profile .MuiCardContent-root:last-child{
	padding-bottom: 40px;
}


@media screen and (max-width: 1000px) {
  .profile .personal-summary{
    width:80%;
  }
}



@media screen and (max-width: 600px) {
  .profile .personal-summary{
    width:90%;
  }

  .personal-summary .field-container .field-label{
		width:100%;
	}

	.personal-summary .field-container-form .MuiInputBase-root, .personal-summary-input{
		width: 100%;
	}
}