.utility-bar .search-fields:after, .search-fields:before{
	border-bottom: none !important;
}

.utility-bar .search-fields .MuiSelect-select:focus{
	background: none !important;
}

.utility-bar .search{
	border: 1.5px solid rgba(0,0,0,.3);
}

.utility-bar input{
	padding: 0 10px;
	border: none;
	outline: none !important;
}

.utility-bar{
	padding: 0px !important;
}

.utility-bar input{
	background: none;
}